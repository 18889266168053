import * as React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import { Intro } from 'components/intro/Intro';
import { Container } from 'components/container/Container';
import { Item } from 'components/item/Item';

export const query = graphql`
  query {
    allContentfulHofundur (sort: { order: ASC, fields: name}) {
      edges {
        node {
          name
          id
        }
      }
    }
  }
`;

export default ({ data }: any) => {
  const { allContentfulHofundur: { edges }} = data;
  return (
    <>
      <Helmet title="Höfundar" />

      <Intro>
        Allir höfundar
      </Intro>
      <Container>
        <ul>
          {edges.sort((a: any, b: any) => { return a.node.name.localeCompare(b.node.name) }).map(({ node: { name, id }}: any) =>
            <Item key={id}  to={`/hofundur/${id}`} title={name} />,
          )}
        </ul>
      </Container>

    </>
  );
};
